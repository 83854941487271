.inv-detail-su {
  border-radius: 0.2rem;
  padding: 0.5rem;
  background-color: #e9ecef;
}
.credi-inv-wrap {
  width: 250px;
}
.credi-inv-wrap .credi-nt-list-item {
  border-radius: 0.2rem;
  padding: 0.5rem;
  border: 1px solid #e9ecef;
  cursor: pointer;
}
.credi-inv-wrap .credi-nt-list-item:hover {
  background-color: #f8f9fa;
}
