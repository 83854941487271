.payment-detail-drawer .ant-drawer-body {
  padding: 0px;
  margin-bottom: 53px;
}
.pym-de {
  position: relative;
  height: 100%;
}
.pym-tile {
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
  width: 100%;
}
.pym-tile.gray-200 {
  background-color: #e9ecef;
}
.pym-tile.gray-100 {
  background-color: #f8f9fa;
}
.pym-de-footer {
  border-top: 1px solid #e9ecef;
  background-color: #fff;
  width: 100%;
}
