.cardledger {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
.cardledger-item {
  display: flex;
  padding: 5px 18px;
  color: #343a40;
  border-bottom: 1px dashed #ced4da;
  justify-content: center;
}
.cardledger-amt {
  display: flex;
  justify-content: flex-end;
  padding: 5px 18px;
  border-right: 1px solid #adb5bd;
  width: 50%;
}
.cardledger-amt:last-child {
  border: 0 transparent;
}
.cardledger-wrap-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.cardledger-wrap-inner {
  display: flex;
  flex-direction: row;
  width: 300px;
  padding: 12px;
}
